/**
 * Sanitize HTML content to prevent XSS attacks
 * Use this function whenever rendering user-generated content
 */
export function sanitizeHtml(
	html: string,
	browser: boolean,
	callback: (sanitized: string) => void,
): void {
	if (!html) {
		callback('')
		return
	}

	if (typeof window !== 'undefined' && browser) {
		import('dompurify')
			.then((DOMPurifyModule) => {
				const DOMPurify = DOMPurifyModule.default
				const sanitized = DOMPurify.sanitize(html, {
					ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'p', 'br', 'ul', 'ol', 'li'],
					ALLOWED_ATTR: ['href', 'target', 'rel'],
				})
				callback(sanitized)
			})
			.catch((err) => {
				console.error('Error sanitizing HTML:', err)
				callback(html.replace(/<[^>]*>?/gm, ''))
			})
	} else {
		callback(html.replace(/<[^>]*>?/gm, ''))
	}
}

export function sanitizeAllTagsHtml(
	html: string,
	browser: boolean,
	callback: (sanitized: string) => void,
): void {
	if (!html) {
		callback('')
		return
	}

	if (typeof window !== 'undefined' && browser) {
		import('dompurify')
			.then((DOMPurifyModule) => {
				const DOMPurify = DOMPurifyModule.default
				const sanitized = DOMPurify.sanitize(html, {
					ALLOWED_TAGS: [],
					ALLOWED_ATTR: [],
					KEEP_CONTENT: true,
				})
				callback(sanitized)
			})
			.catch((err) => {
				console.error('Error sanitizing HTML:', err)
				callback(html.replace(/<[^>]*>?/gm, ''))
			})
	} else {
		callback(html.replace(/<[^>]*>?/gm, ''))
	}
}
